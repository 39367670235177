<script setup lang="ts">
interface Product {
  bar_code: string
  code: string
  id: number
  image_url: string
  product_id: number
  search_terms: string | null
  title: string
  stock: number
}

const { t } = useI18n()
const route = useRoute()
const search = ref('')
const searchResults = ref()

const hidden = ref(true)
onClickOutside(searchResults, () => hidden.value = true)
watch(() => route.path, () => {
  hidden.value = true
})
const { data } = await useApi<Product[]>('/api/variants')

const { results, loading } = useFuseSearch(
  search,
  data,
  {
    fuseOptions: {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: [
        'title', // will be assigned a `weight` of 1
        {
          name: 'code',
          weight: 2,
        },
        {
          name: 'bar_code',
          weight: 2,
        },
        {
          name: 'search_terms',
          weight: 2,
        },
      ],
    },
    resultLimit: 30,
  },
)
</script>

<template>
  <div ref="searchResults" class="z-200 relative w-full">
    <div class="bg-sand max-w-screen flex flex-row justify-between rounded-xl bg-opacity-40">
      <input
        v-model="search" class="bg-sand h-full w-full rounded-l-xl bg-opacity-0 p-4"
        :placeholder="t('navbar.search_placeholder')" @focus="hidden = false"
      >
      <img class="rounded-r-xl px-4 " src="~/assets/images/icons/search.svg" alt="Search">
    </div>
    <div
      v-if="search && !hidden && !loading"
      class="max-w-screen shadow-input z-200 max-h-100 absolute inset-x-0 top-16 flex transform flex-col divide-y overflow-y-auto overflow-x-hidden rounded-lg bg-white px-1"
    >
      <div v-if="results && results.length === 0" class="md:w-100 w-screen rounded-xl px-4 py-3">
        {{ t('product.search_empty') }}
      </div>
      <div v-for="product in results" v-else :key="product.item?.id" class="">
        <NuxtLinkLocale
          class="hover:bg-primary-light hover:text-primary my-1 flex flex-row rounded-lg p-2"
          :to="product.item.code ? `/product/${product.item.product_id}?code=${product.item.code}` : `/product/${product.item.product_id}`"
        >
          <div class="flex gap-3 w-full">
            <img
              class="h-10 w-10 rounded-md object-contain bg-white flex-none" :class="product.item?.image_url ? '' : 'opacity-20'"
              :src="useImageProxy(product?.item?.image_url ?? '', 960, 0)"
              alt=""
            >
            <div style="width: calc(100% - 48px)">
              <div class="font-bold truncate">
                {{ product.item.title }}
              </div>
              <div class="opacity-25 tabular-nums text-sm">
                {{ product.item.code }}
              </div>
            </div>
          </div>
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>
