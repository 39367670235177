<script setup>
const { t, locale } = useI18n({})
const currentYear = new Date().getFullYear()

const configuratorLink = computed(() => {
  return `https://tool.giardino-online.com?language=${locale.value}`
})
</script>

<template>
  <div class="border-t pt-8">
    <div class="container mx-auto flex w-full flex-col items-center justify-between gap-4 text-center text-lg md:flex-row md:items-start md:text-left">
      <div class="flex flex-col gap-8">
        <div class="flex justify-center md:justify-start">
          <img src="~/assets/images/giardino-logo.svg" alt="Giardino logo">
        </div>
        <div>
          <a
            href="https://goo.gl/maps/ZGn6sCCVTPfHCedP7"
            target="_blank"
          >
            <div>Twaalfbunderweg 13</div>
            <div>B-3740 Bilzen</div>
          </a>
          <div>BTW: BE0441.446.505</div>
          <div class="mt-2 flex justify-center md:justify-start gap-2">
            <a href="https://www.facebook.com/giardinogti" target="_blank">
              <span class="i-mdi-facebook h-6 w-6 block" />
            </a>
            <a href="https://www.instagram.com/giardino_gti/" target="_blank">
              <span class="i-mdi-instagram h-6 w-6 block" />
            </a>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-1">
        <div class="font-bold">
          {{ t('footer.offering') }}
        </div>
        <div>
          <a :href="configuratorLink" target="_blank">
            <div class="flex items-center gap-2">
              <span>{{ t('navbar.dropdown.configurator') }}</span>
              <span class="i-mdi-open-in-new" />
            </div>
          </a>

          <a href="https://issuu.com/giardino-gardentrade/stacks/810d33d17dfc4fb39b54cd5f7ef67f1c" target="_blank">
            <div class="flex items-center gap-2">
              <span>{{ t('footer.catalog') }}</span>
              <span class="i-mdi-open-in-new" />
            </div>
          </a>

          <div>
            <NuxtLinkLocale to="/products/garden/all">
              {{ t('navbar.products') }}
            </NuxtLinkLocale>
          </div>

          <div>
            <NuxtLinkLocale to="/advice">
              {{ t('navbar.advice') }}
            </NuxtLinkLocale>
          </div>

          <div>
            <NuxtLinkLocale to="/blog">
              {{ t('navbar.blog') }}
            </NuxtLinkLocale>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-1">
        <div class="font-bold">
          Giardino
        </div>
        <div>
          <div>
            <NuxtLinkLocale to="/team">
              {{ t('navbar.team') }}
            </NuxtLinkLocale>
          </div>

          <div>
            <NuxtLinkLocale to="/dealers">
              {{ t('navbar.dealers') }}
            </NuxtLinkLocale>
          </div>

          <div>
            <NuxtLinkLocale to="/green">
              {{ t('navbar.giardino_goes_green') }}
            </NuxtLinkLocale>
          </div>

          <!-- <div>
            <NuxtLinkLocale to="/history">
              {{ t('navbar.history') }}
            </NuxtLinkLocale>
          </div> -->

          <div>
            <NuxtLinkLocale to="/service">
              {{ t('footer.customer_service') }}
            </NuxtLinkLocale>
          </div>

          <div>
            <NuxtLinkLocale to="/faq">
              {{ t('navbar.faq') }}
            </NuxtLinkLocale>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-1">
        <div class="font-bold">
          {{ t('footer.delivery') }}
        </div>
        <div>
          <div class="mt-2 flex flex-row justify-center gap-1 md:justify-start">
            <img src="~/assets/images/footer/bpost.svg" alt="Bpost">
            <img src="~/assets/images/footer/dpd.svg" alt="dpd">
            <img src="~/assets/images/footer/postnl.svg" alt="postnl">
          </div>
        </div>
        <div class="mt-8 font-bold">
          {{ t('footer.payment_methods') }}
        </div>
        <div>
          <div class="mt-2 flex flex-row justify-center gap-1 md:justify-start">
            <img src="~/assets/images/footer/bancontact.svg" alt="Bancontact">
            <img src="~/assets/images/footer/mastercard.svg" alt="Mastercard">
            <img src="~/assets/images/footer/visa.svg" alt="Visa">
            <img src="~/assets/images/footer/paypal.svg" alt="Paypal">
            <img src="~/assets/images/footer/ideal.svg" alt="ideal">
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto mt-8 flex w-full flex-col items-center justify-between gap-4 text-center text-lg opacity-40 md:flex-row md:items-start md:text-left">
      <div>
        <div>
          {{ t('footer.copyright', { year: currentYear }) }}
        </div>
      </div>
      <div class="flex space-x-4">
        <NuxtLinkLocale to="/page/terms-b2c">
          B2C
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/page/terms-b2b">
          B2B
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/page/privacy">
          {{ t('footer.privacy') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/page/gdpr">
          {{ t('footer.gdpr') }}
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>
